export default function Footer() {
    return (<>
        <div className="footer">
            <div className="container">
                <p style={{"width": "80%", "float": "left", "textAlign": "justify"}}>
                    <img src="/template/images/logo.png" className="footer__logo logo" style={{float: "none"}}/>
                    This site is a free service to assist homeowners in connecting with local service contractors.
                    All contractors are independent and this site does not warrant or guarantee any work performed.
                    It is the responsibility of the homeowner to verify that the hired contractor furnishes the
                    necessary license and insurance required for the work being performed. All persons depicted in a
                    photo or video are actors or models and not contractors listed on this site.</p>
                <div className="footer-copyright" style={{marginTop: "75px"}}>
                    <div className="footer-copyright__row">© 2022 ReModelCompare.</div>
                    <div className="footer-copyright__row">
                        <a href="/privacy">Privacy Notice</a> | <a href="/terms-of-use">Terms of Use</a>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
