import React from 'react';
import Footer from "./Footer";

export default function Partners() {
    var BreadCrumbTitle = "Companies and Partners";
    document.title = BreadCrumbTitle;
    return (<>
            <div className="header" id="homeowner-header">
                <div className="container">
                    <div className="header__desktop hidden-xs hidden-sm"><a href="/">
                        <img src="/template/images/logo.png" className="header__logo logo"/>
                    </a>
                    </div>
                    <div className="header__mobile">
                        <a className="header__mobile-logo logo logo--dark-color"
                           aria-label="Modernize"></a>
                        <div className="header__mobile-btns closed" id="toggle-mobile-nav"></div>
                    </div>
                </div>
            </div>
            <div className="home-hero" show-sticky-header="">
                <div className="home-hero__container">
                    <div className="home-hero__bg" id="hero-bg">
                        <picture className="home-hero__bg-picture">
                            <source media="(max-width: 767px)"
                                    srcSet="https://modernize.com/wp-content/uploads/2021/07/modernize-newhero-mob.jpg"/>
                            <img src="/template/images/modernize-newhero-desk.jpg" alt="" className="lazyloaded"
                                 data-ll-status="loaded"/>
                            <noscript><img
                                src="https://modernize.com/wp-content/uploads/2021/07/modernize-newhero-desk.jpg"
                                alt=""/></noscript>
                        </picture>
                    </div>
                    <div className="home-hero__content">
                        <div className="container" style={{marginTop: "40px"}}>
                            <div className="col-sm-12 col-lg-8">
                                <h1 className="home-hero__content-title">
                                    Better&nbsp;Pros. Better&nbsp;Homes.
                                </h1>
                                <h2 className="home-hero__content-subtitle">
                                    ReModelCompare is the reliable and trustworthy solution to
                                    thoughtfully connect homeowners with professionals who provide
                                    home improvement projects and services.
                                </h2>
                            </div>
                            <div className="col-sm-12 col-lg-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container" style={{margin: "-24px"}}>
                    <div className="ar-block__cta">
                        <a id="ar-block-cta"> </a>
                    </div>
                </div>
            </div>
            <div className="page-content deferred">
                <div className="byp-block" style={{"backgroundColor": "#f1f1f1"}}>
                    <div className="byp-block__container">
                        <h2 className="byp-block__title" style={{"margin": "30px 0"}}>
                            Marketing Partners
                        </h2>
                        <div className="byp-block__row">
                            <a className="byp-block__step byp-block__step--a" style={{"width": "100%"}}>
                                <div className="byp-block__step-info" style={{"width": "100%", "textAlign": "left"}}>
                                    <p>Matched companies included, but not limited to: ADT, 5 Mile Media, ABS
                                        Healthcare, Ace Solutions, Admaric, Admediary, Adsparkx Digital, Advantage One
                                        Insurance, Advocator Group, Aetna, AgedLeadStore.com, AHCP, AIG, All Digital
                                        Promotions, All Web Leads, Allied Insurance, Allied Insurance Partners (view
                                        list), Allstarz, Alphatech Resource Holdings s.r.o., America’s Health Advisors,
                                        American Auto Club, American Continental, American Disability Helpline, American
                                        Family, American Health Reform Solutions, American Insurance Organization, LLC,
                                        American Republic, American Select Health, Andy Montague Region, Anthem/BCBS,
                                        Assurant, Bankers Life and Casualty, ATI Agency, Audience Serv, AutoGuard Pro,
                                        Automotive Services Center, Bad Ass Leads, Bankrate, BBRS Group, Beacon Leads,
                                        LLC, Benefit Advisors, BestQuotes, Blue Nile, BlueCross/Blue Sheild Association
                                        Companies, Bold Media Group, Boost, Boost Health Insurance, Boost Marketing Inc,
                                        Btel, Btel Communications, C3 Data, LLC, Call Center Partnerships, Cedar Tree
                                        Media, Cege Media, Cege Media, Choice Direct, Cigna, Coventry, Fortis, Citirex,
                                        Citizens Disability, Clean Energy Concepts, Clearlink, Compass Health Advisors,
                                        Connect Plus, Connect Rite Bpo, Contactability, Corner Shop Media, Crosspointe
                                        Insurance Advisors, D and D Investment Specialist, Datalot, Datwerx, Digital
                                        Market Media, Disability Advisor, DiscoverBPO, DKS Marketing, DPMP Media Inc.,
                                        e-TeleQuote Insurance, Inc., Emerald Enterprise Corp, Energy Alliance, Excel
                                        Impact, Family First Health Plans, FCHCN Insurance Agency, Field of Leads Ltd.,
                                        Fields of Leads, Final Expense Assistant, Financial Grade, First Family,
                                        FirstAmericanFinancial, FiveStrata, Flyhigh, Genesis Solutions Group, Genworth
                                        Financial, Gerber Life, GHI, GigUp, Globalsky BPO, GoHealth, Golden Rule, Grey
                                        Peaks, Guardian Benefits, Guide to Insure, Guidestar Marketing Group LLC,
                                        HasTraffic, HauteProspects, Health Benefits Center, Health Benefits One, Health
                                        Dime, Health Insurance Advantage, Health Insurance Services, Health Net,
                                        Healthcare.com, HealthIQ, HealthMarkets/Insphere, HealthPlanOne, HealthSpire
                                        Calls, Heard and Smith, Highmark, HK Flavors Limited, Home Bundles, Home Path,
                                        Home Savings, HomeWarrantyProject.com, HP One, HPO, Humana, iCall Solutions,
                                        Ideal Concepts, Ideal Concepts, Inc, Innovate Financial Group, Inside Response,
                                        Insphere, Insuralife, Insurance Guide, Insurance Line One, Insurance Services,
                                        Insurance Solutions Direct LLC, Insurance Solutions LLC, Kaiser, Medicare
                                        Solutions, Kallstar, Kevin Ferrell Inc, Lead Origins, Lead Realm LLC, Legends
                                        United Insurance Agency, Lender Daily, Lendmarx, Lighthouse Insurance Group,
                                        Line East Leads, ListenClear, LizDev Inc., LizDev, Inc, Medicare Concepts, LLC,
                                        Momentum Solar, Mutual of Omaha, MyHealthAngel.com, Narrate LLC, National
                                        Automotive Financial Services, National Disability, National Health Connect LLC,
                                        National Health Hub LLC, National Healthcare Advisors, National Income Life
                                        Insurance Company, National Repair Solutions, Nationwide Health Advisors, Next
                                        Wave Marketing, Next Wave Marketing Strategies, Inc, Northwest Medical, Number
                                        One Health Insurance Agency, Onyx Digital Media, OpenJar Concepts, Otis
                                        Production LLC, Ox Car Care, Palo Media Group, PCS, Peak Performance, Ping
                                        Leads, Pinnacle Publishers LLC, Policy Scout, PolicyBind, PolicyScout, Power
                                        Credit Solutions, Power Source Marketing, Power Source Marketing LLC, Practical
                                        Marketing, Precise Leads, Premier Disability, Premier Health Advisors, Inc.,
                                        Premier Health Associates, Presidio Interactive, Presidio Interactive Corp,
                                        Purple Dog Marketing LLC, Q3M Insurance Solutions, Qatalyst, Quantum 3 Media
                                        LLC, QuickQuote, Quote Velocity, Quotefire, QuoteStorm, Quotewizard, Rank Media,
                                        RateMarketPlace, Reliable Partners (Trips Marketing), Resource Marketing Corp.,
                                        ReviMedia, Rogue E-Cigs, S.B. Fintech Ltd, Seascape, Seascape Health, Seascape
                                        Life, Secure Horizons, Select Quote, SelectQuote, SelectQuote, SeniorCare USA,
                                        Simple Health, Simple Health Plans Inc., Simple Insurance Leads, Singularity
                                        Media, Smart Match Insurance Agency, Smart Match Insurance Solutions, SmartHome
                                        Solutions USA, SolidQuote, Spring EQ, Spring Venture Group, SS Telemarketing,
                                        Sterling, SubjectWell, Sun City Financial LLC, Support First, Synergy Marketing
                                        Associates, Tallac Media, The Medicare Assistant, The-Solar-Project, Thrive
                                        Marketing, Tiger Health Solutions, Torchlight Technology Group LLC, TradeMarc
                                        Global, LLC, Traffic Panda, Trips Marketing LLC, Trusted Consumer Advisors, TZ
                                        Insurance Solutions LLC, United Access insurance, United Healthcare, United
                                        Insurance Group Agency, Inc., United Medicare, United Medicare Advisors,
                                        Universal American, US Dealer Services, US Health Advisors, US Health Advisors,
                                        US Insurance Advisors, LLC, US Marketing Group, USHEALTH Advisors, Vertigen LLC,
                                        Visiqua, Voxify, Voxify LLC, WeCall, WellPoint, West Coast Closers, North Star
                                        Insurance Advisors, Premier Producers Group and Senior Benefits, Yasha
                                        Marketing, Vivint.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
