import {useRef, useState} from 'react';
import axios from "axios";

import Footer from "./Footer";

export default function Home() {
    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [requesttype, setRequestType] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message});
    const [formResponse, setFormResponse] = useState('');
    const lead_idRef = useRef(null);


    const handleSubmit = async () => {
        const lead_id = lead_idRef.current.value;
        if (lead_id == '') {
            //handleSubmit();
            return false;
        }

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "remodelcompare",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "",
            // "lead_id": document.getElementById('leadid_token').value,
            "lead_id": lead_id,
            "ip_address": '',
            "remarks": "Request Type: " + requesttype + ". " + message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://remodelcompare.digitalwim.com/process_api.php';
        //const url = 'http://localhost/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        console.log(result.data.status);
        if (result.data.status == 'fail') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-danger">' + result.data.message + '</div>';
        }
        if (result.data.status == 'success') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-success">' + result.data.message + '</div>';
            const timeout = setTimeout(() => {
                window.location.replace('/');
            }, 2000);
        }
    };

    (function () {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//create.lidstatic.com/campaign/c5195985-cd3b-57bd-dd36-6a207a7288b5.js?snippet_version=2';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();


    return (
        <>
            <div className="header" id="homeowner-header">
                <div className="container">
                    <div className="header__desktop hidden-xs hidden-sm"><a href="/">
                        <img src="template/images/logo.png" className="header__logo logo"/>
                    </a>

                        <div className="header__links">
                            <div className="header__link static" data-toggle="modal" data-target="#how-it-works-modal">
                                How it works
                            </div>
                            <a href="#menu2">
                                <div className="header__link static" data-target="#menu2">
                                    Plan Your Project
                                </div>
                            </a>

                            <a href="#menu2">
                                <div className="header__link static" data-target="#menu2">
                                    +1 (609) 269 0925
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="header__mobile">
                        <a className="header__mobile-logo logo logo--dark-color"
                           aria-label="Modernize"></a>
                        <div className="header__mobile-btns closed" id="toggle-mobile-nav"></div>
                    </div>
                </div>
            </div>

            <div className="page-overlay"></div>
            <div className="modal fade how-it-works-modal" style={{display: "none"}} id="how-it-works-modal"
                 tabIndex="-1"
                 role="dialog"
                 aria-labelledby="how-it-works-modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">
							<i className="icon-close"></i>
						</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-title h4">How ReModelCompare Works</div>
                            <div className="how-it-works how-it-works--desktop">
                                <div className="row">
                                    <div className="col-xs-3 how-it-works__column">
                                        <div className="sub-nav__item">
                                            <div className="sub-nav__item-image">
                                                <img src="template/images/clipboard.png"
                                                     alt="Tell us about your home improvement project."
                                                     data-lazy-src="template/images/clipboard.png"/>
                                                <noscript><img
                                                    src="template/images/clipboard.png"
                                                    alt="Tell us about your home improvement project."/></noscript>
                                            </div>
                                            <div className="sub-nav__item-title how-it-works__title">
                                                Tell us about your home improvement project.
                                            </div>
                                            <div className="sub-nav__item-text how-it-works__text">
                                                Answer a few questions about your needs and preferences
                                                for your upcoming project.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 how-it-works__column">
                                        <div className="sub-nav__item">
                                            <div className="sub-nav__item-image">
                                                <img src="template/images/engage.png"
                                                     alt="Modernize pairs you with professionals."
                                                     data-lazy-src="template/images/engage.png"/>
                                                <noscript><img
                                                    src="template/images/engage.png"
                                                    alt="Modernize pairs you with professionals."/></noscript>
                                            </div>
                                            <div className="sub-nav__item-title how-it-works__title">
                                                Modernize pairs you with professionals.
                                            </div>
                                            <div className="sub-nav__item-text how-it-works__text">
                                                We will match you with up&nbsp;to&nbsp;four reliable local
                                                professionals by email, phone, and text.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 how-it-works__column">
                                        <div className="sub-nav__item">
                                            <div className="sub-nav__item-image">
                                                <img src="template/images/engage1.png"
                                                     alt="Select your contractor or remodeler."
                                                     data-lazy-src="template/images/engage1.png"/>
                                                <noscript><img
                                                    src="template/images/engage1.png"
                                                    alt="Select your contractor or remodeler."/></noscript>
                                            </div>
                                            <div className="sub-nav__item-title how-it-works__title">
                                                Select your contractor or remodeler.
                                            </div>
                                            <div className="sub-nav__item-text how-it-works__text">
                                                Read recent contractor reviews and schedule no-obligation
                                                appointments.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-3 how-it-works__column">
                                        <div className="sub-nav__item">
                                            <div className="sub-nav__item-image">
                                                <img src="template/images/rocket.png"
                                                     alt="Begin your project with confidence."
                                                     data-lazy-src="template/images/rocket.png"/>
                                                <noscript><img
                                                    src="template/images/rocket.png"
                                                    alt="Begin your project with&nbsp;confidence."/></noscript>
                                            </div>
                                            <div className="sub-nav__item-title how-it-works__title">
                                                Begin your project with&nbsp;confidence.
                                            </div>
                                            <div className="sub-nav__item-text how-it-works__text">
                                                Read contractor reviews, compare quotes, and book
                                                appointments with professionals.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="how-it-works how-it-works--mobile">
                                <div className="swiper-container" id="hiw-swiper-container">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="sub-nav__item">
                                                <div className="sub-nav__item-image">
                                                    <img
                                                        src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                        alt="Tell us about your home improvement project."
                                                        data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-1.svg"/>
                                                    <noscript><img
                                                        src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-1.svg"
                                                        alt="Tell us about your home improvement project."/></noscript>
                                                </div>
                                                <div className="sub-nav__item-title how-it-works__title">
                                                    Tell us about your home improvement project.
                                                </div>
                                                <div className="sub-nav__item-text how-it-works__text">
                                                    Answer a few questions about your needs and preferences
                                                    for your upcoming project.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="sub-nav__item">
                                                <div className="sub-nav__item-image">
                                                    <img
                                                        src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                        alt="Modernize pairs you with professionals."
                                                        data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-2.svg"/>
                                                    <noscript><img
                                                        src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-2.svg"
                                                        alt="Modernize pairs you with professionals."/></noscript>
                                                </div>
                                                <div className="sub-nav__item-title how-it-works__title">
                                                    Modernize pairs you with professionals.
                                                </div>
                                                <div className="sub-nav__item-text how-it-works__text">
                                                    We will match you with up&nbsp;to&nbsp;four reliable
                                                    local professionals by email, phone, and text.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="sub-nav__item">
                                                <div className="sub-nav__item-image">
                                                    <img
                                                        src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                        alt="Select your contractor or remodeler."
                                                        data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-3.svg"/>
                                                    <noscript><img
                                                        src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-3.svg"
                                                        alt="Select your contractor or remodeler."/></noscript>
                                                </div>
                                                <div className="sub-nav__item-title how-it-works__title">
                                                    Select your contractor or remodeler.
                                                </div>
                                                <div className="sub-nav__item-text how-it-works__text">
                                                    Read recent contractor reviews and schedule
                                                    no-obligation appointments.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="sub-nav__item">
                                                <div className="sub-nav__item-image">
                                                    <img
                                                        src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                        alt="Begin your project with confidence."
                                                        data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-4.svg"/>
                                                    <noscript><img
                                                        src="https://modernize.com/wp-content/themes/modernize/dist/images/header-hiw/hiw-4.svg"
                                                        alt="Begin your project with&nbsp;confidence."/></noscript>
                                                </div>
                                                <div className="sub-nav__item-title how-it-works__title">
                                                    Begin your project with&nbsp;confidence.
                                                </div>
                                                <div className="sub-nav__item-text how-it-works__text">
                                                    Read contractor reviews, compare quotes, and book
                                                    appointments with professionals.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-pagination" id="hiw-swiper-pagination"></div>
                                </div>
                            </div>
                            <div className="sub-nav__btn-container btn-container btn-container--centered">
                                <button data-bind="find-pro-modal-open-btn" data-toggle="" data-target=""
                                        data-dismiss="modal"
                                        className="hiw-btn btn btn-sm btn-link btn-info">
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header__nav-accordion" id="mobile-nav-menu" style={{display: "none"}}>
                <div className="accordion" id="nav-accordion">
                    <div className="header__link static" data-toggle="modal" data-target="#how-it-works-modal">
                        How it works
                    </div>

                    <div className="header__link header__link--dropdown">
                        <div className="header__link-title collapsed" id="trades-list-title" data-toggle="collapse"
                             data-target="#trades-list-mobile">
                            <span>Plan Your Project</span>
                        </div>
                        <div className="collapse" id="trades-list-mobile" data-parent="#nav-accordion">
                            <div className="header__link-dropdown">
                                <div className="sub-nav__container">
                                    <div className="sub-nav__item">
                                        <a hclass="sub-nav__trade-links-link">
                                            Bathroom
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Cabinets
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Doors
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Flooring
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Plumbing
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Home Accessibility
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Fencing
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Home Warranty
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Hot Tubs
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            HVAC
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Kitchen Remodel
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Kitchens
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Roofing
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Siding
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Solar
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Stair Lifts
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Walk In Tubs
                                        </a>
                                    </div>
                                    <div className="sub-nav__item">
                                        <a className="sub-nav__trade-links-link">
                                            Windows
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header__link static" data-bind="find-pro-modal-open-btn">
                        Find local contractors
                        <span data-bind="find-pro-modal-open-btn" className="header__link-title"></span>
                    </div>
                    <div className="header__link header__link--contractor static" data-bind="contractor-button">
                        I'm a contractor
                        <a className="header__link-title"></a>
                    </div>
                    <div data-bind="header-login-menu-item" className="header__link static header__link--login">
                        Manage your Project
                        <a rel="nofollow" className="header__link-title"
                           data-bind="header-login-link"></a>
                    </div>
                </div>

                <form method="get" className="header__nav-search-form" __bizdiag="115"
                      __biza="WJ__">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Search" name="s" required="" value=""/>
                    </div>
                </form>
            </div>

            <div className="home-hero" show-sticky-header="">
                <div className="home-hero__container">
                    <div className="home-hero__bg" id="hero-bg">
                        <picture className="home-hero__bg-picture">
                            <source media="(max-width: 767px)" srcSet="
                https://modernize.com/wp-content/uploads/2021/07/modernize-newhero-mob.jpg
              "/>
                            <img src="template/images/modernize-newhero-desk.jpg" alt="" className="lazyloaded"
                                 data-ll-status="loaded"/>
                        </picture>
                    </div>
                    <div className="home-hero__content">
                        <div className="container" style={{marginTop: "40px"}}>
                            <div className="col-sm-12 col-lg-8">
                                <h1 className="home-hero__content-title">
                                    Better&nbsp;Pros. Better&nbsp;Homes.
                                </h1>
                                <h2 className="home-hero__content-subtitle">
                                    ReModelCompare is the reliable and trustworthy solution to
                                    thoughtfully connect homeowners with professionals who provide
                                    home improvement projects and services.
                                </h2>

                                <div className="navigation">
                                    <div className="navigation__card">
                                        <div className="tabs" id="tabs-container">
                                            <button type="button" className="tabs__tab tabs__tab--active"
                                                    data-value="trending">
                                                Trending
                                            </button>
                                            <button type="button" className="tabs__tab" data-value="exterior">
                                                Exterior
                                            </button>
                                            <button type="button" className="tabs__tab" data-value="interior">
                                                Interior
                                            </button>
                                        </div>

                                        <ul className="links" data-selected="trending" id="link-container">
                                            <li className="links__item links__item--window"
                                                data-categories="trending exterior"
                                                data-index="1">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M27.5024 12.5006H42.5044V28.2526H27.5024"
                                                                      fill="white">
                                                                </path>
                                                                <path d="M27.5024 12.5006H42.5044V28.2526H27.5024"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M6.49902 39.5035H23.751V9.5005H6.49902V39.5035Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M6.5 39.5035H23.752V9.4995H6.5V39.5035Z"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M20.0017 24.1266C20.8297 24.1266 21.5017 24.7986 21.5017 25.6266C21.5017 26.4556 20.8297 27.1266 20.0017 27.1266C19.1737 27.1266 18.5017 26.4556 18.5017 25.6266C18.5017 24.7986 19.1737 24.1266 20.0017 24.1266Z"
                                                                      fill="#070441"></path>
                                                                <path d="M34.6284 12.5006V28.2526" stroke="#070441"
                                                                      stroke-linecap="square"></path>
                                                                <path d="M27.8774 20.3766H42.1294" stroke="#070441"
                                                                      stroke-linecap="square"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Windows  &amp;<br/>
                                                        Doors
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--hvac"
                                                data-categories="trending exterior interior" data-index="2">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M13.5 41.6985H34.828V7.5005H13.5V41.6985Z"
                                                                      fill="#D5DBF1">
                                                                </path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M13.5 41.6975H34.828V7.4995H13.5V41.6975Z"
                                                                      stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.696 22.0475H29.278V12.7885H18.696V22.0475Z"
                                                                      fill="white">
                                                                </path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.696 22.0475H29.278V12.7885H18.696V22.0475Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path d="M22.2229 15.1656V16.9296" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M25.7502 15.1656V16.9296" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M23.9963 17.9205V19.6845" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.696 36.4545H29.278V27.1955H18.696V36.4545Z"
                                                                      fill="white">
                                                                </path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.696 36.4545H29.278V27.1955H18.696V36.4545Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path d="M22.2229 29.5719V31.3359" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M25.7502 29.5719V31.3359" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M23.9963 32.3277V34.0917" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">AC &amp; Heating</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--roofing"
                                                data-categories="trending exterior"
                                                data-index="3">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M14.5325 15.5006L25.6835 28.8696H43.8445L32.5775 15.5006H14.5325ZM3.38354 28.8696L14.5325 15.5006L3.38354 28.8696Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path
                                                                    d="M3.38354 28.8694L14.5325 15.5004L25.6835 28.8694H43.8445L32.5775 15.5004H14.5325"
                                                                    stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M25.4939 33.4173H44.1209" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Roofing</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--shower"
                                                data-categories="trending interior"
                                                data-index="4">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.4205 27.8078H40.2455V35.5298C40.2455 39.3208 37.1475 42.3938 33.3245 42.3938H15.4205C11.5985 42.3938 8.49951 39.3208 8.49951 35.5298V27.8078H11.2735H15.4205Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.4205 27.8078H40.2455V35.5298C40.2455 39.3208 37.1475 42.3938 33.3245 42.3938H15.4205C11.5985 42.3938 8.49951 39.3208 8.49951 35.5298V27.8078H11.2735H15.4205Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M8.49951 36.3879H40.2455C40.2455 39.7039 37.5565 42.3939 34.2395 42.3939H14.5055C11.1885 42.3939 8.49951 39.7039 8.49951 36.3879Z"
                                                                      fill="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M26.9976 15.7961H29.9496C29.9496 12.9521 27.6446 10.6481 24.8016 10.6481C23.1536 10.6481 21.6866 11.4221 20.7446 12.6271C20.0606 13.5011 19.6536 14.6011 19.6536 15.7961H22.7296H26.9976Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M26.9976 15.7961H29.9496C29.9496 12.9521 27.6446 10.6481 24.8016 10.6481C23.1536 10.6481 21.6866 11.4221 20.7446 12.6271C20.0606 13.5011 19.6536 14.6011 19.6536 15.7961H22.7296H26.9976Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path
                                                                    d="M35.0973 27.6604V10.6174C35.0973 7.7914 32.7923 5.5004 29.9503 5.5004C27.1073 5.5004 24.8013 7.7914 24.8013 10.6174"
                                                                    stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M21.7983 19.6565V23.0895" stroke="#070441"
                                                                      stroke-linecap="round"></path>
                                                                <path d="M24.6938 19.6565V23.0895" stroke="#070441"
                                                                      stroke-linecap="round"></path>
                                                                <path d="M27.5474 19.6565V23.0895" stroke="#070441"
                                                                      stroke-linecap="round"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Bathrooms</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--gutter"
                                                data-categories="trending exterior"
                                                data-index="5">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M16.9825 39.5068H8.50049V32.2358H16.9825C18.9865 32.2358 20.6185 30.6048 20.6185 28.6008V24.9648H27.8885V28.6008C27.8885 34.6138 22.9965 39.5068 16.9825 39.5068Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M16.9825 39.5068H8.50049V32.2358H16.9825C18.9865 32.2358 20.6185 30.6048 20.6185 28.6008V24.9648H27.8885V28.6008C27.8885 34.6138 22.9965 39.5068 16.9825 39.5068Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path d="M40.0068 20.1181V8.00009" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M8.5 29.8124V41.9294" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M20.6321 26.701H27.7711V23.235H20.6321V26.701Z"
                                                                      fill="#10103F"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Plumbing</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--solar" data-categories="exterior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Solar</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--shield"
                                                data-categories="trending interior"
                                                data-index="6">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9632 31.5078V18.4538V14.7218V11.4428C15.9632 11.1968 15.8902 10.9558 15.7542 10.7518L13.2662 7.01981C12.8042 6.32681 11.6572 6.32681 11.1952 7.01981L8.70924 10.7498C8.57224 10.9548 8.50024 11.1948 8.50024 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C8.50024 40.9038 9.05724 41.4598 9.74424 41.4598H14.7192C15.4062 41.4598 15.9632 40.9038 15.9632 40.2158V35.2388V31.5078Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9632 31.5078V18.4538V14.7218V11.4428C15.9632 11.1968 15.8902 10.9558 15.7542 10.7518L13.2662 7.01981C12.8042 6.32681 11.6572 6.32681 11.1952 7.01981L8.70924 10.7498C8.57224 10.9548 8.50024 11.1948 8.50024 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C8.50024 40.9038 9.05724 41.4598 9.74424 41.4598H14.7192C15.4062 41.4598 15.9632 40.9038 15.9632 40.2158V35.2388V31.5078Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M28.3812 31.5078V18.4538V14.7218V11.4428C28.3812 11.1968 28.3082 10.9558 28.1722 10.7518L25.6842 7.01981C25.2222 6.32681 24.0752 6.32681 23.6132 7.01981L21.1272 10.7498C20.9902 10.9548 20.9182 11.1948 20.9182 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C20.9182 40.9038 21.4752 41.4598 22.1612 41.4598H27.1372C27.8242 41.4598 28.3812 40.9038 28.3812 40.2158V35.2388V31.5078Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M28.3812 31.5078V18.4538V14.7218V11.4428C28.3812 11.1968 28.3082 10.9558 28.1722 10.7518L25.6842 7.01981C25.2222 6.32681 24.0752 6.32681 23.6132 7.01981L21.1272 10.7498C20.9902 10.9548 20.9182 11.1948 20.9182 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C20.9182 40.9038 21.4752 41.4598 22.1612 41.4598H27.1372C27.8242 41.4598 28.3812 40.9038 28.3812 40.2158V35.2388V31.5078Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M40.7992 31.5078V18.4538V14.7218V11.4428C40.7992 11.1968 40.7262 10.9558 40.5902 10.7518L38.1022 7.01981C37.6402 6.32681 36.4932 6.32681 36.0312 7.01981L33.5442 10.7498C33.4082 10.9548 33.3352 11.1948 33.3352 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C33.3352 40.9038 33.8932 41.4598 34.5792 41.4598H39.5552C40.2412 41.4598 40.7992 40.9038 40.7992 40.2158V35.2388V31.5078Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M40.7992 31.5078V18.4538V14.7218V11.4428C40.7992 11.1968 40.7262 10.9558 40.5902 10.7518L38.1022 7.01981C37.6402 6.32681 36.4932 6.32681 36.0312 7.01981L33.5442 10.7498C33.4082 10.9548 33.3352 11.1948 33.3352 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C33.3352 40.9038 33.8932 41.4598 34.5792 41.4598H39.5552C40.2412 41.4598 40.7992 40.9038 40.7992 40.2158V35.2388V31.5078Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Fencing</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--umbrella" data-categories="trending"
                                                data-index="7">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M6.79932 20.453L10.5723 20.502V41.077H24.1323V8.784L6.79932 20.453Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M24.2727 41.0765H37.8327V20.5015L41.6067 20.4525L24.2727 8.78448V41.0765Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M6.50049 20.5015L24.1465 8.49951L41.7915 20.5015H6.50049Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M10.572 41.0769H37.719V20.5019H10.572V41.0769Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M37.719 34.2183H24.146" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M37.719 27.3599H24.146" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M24.146 41.0767V8.49969" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M30.933 13.6441H24.146" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path d="M30.9331 13.6441V41.0771" stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Home Warranty</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--medical" data-categories="trending"
                                                data-index="8">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Kitchens</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--siding" data-categories="exterior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M11.8879 36.8931H28.4299V29.7551H11.8879V36.8931Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M11.8879 29.7552H28.4299" stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M26.0512 26.3802H14.6692C14.1162 26.3802 13.6692 25.9332 13.6692 25.3802V21.2432C13.6692 20.1392 14.5652 19.2432 15.6692 19.2432H25.0512C26.1552 19.2432 27.0512 20.1392 27.0512 21.2432V25.3802C27.0512 25.9332 26.6042 26.3802 26.0512 26.3802Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M26.0512 26.3802H14.6692C14.1162 26.3802 13.6692 25.9332 13.6692 25.3802V21.2432C13.6692 20.1392 14.5652 19.2432 15.6692 19.2432H25.0512C26.1552 19.2432 27.0512 20.1392 27.0512 21.2432V25.3802C27.0512 25.9332 26.6042 26.3802 26.0512 26.3802Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path d="M25.8391 29.7552L45.2401 10.3542"
                                                                      stroke="#10103F"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M16.6421 29.4061H24.0771V26.5821H16.6421V29.4061Z"
                                                                      fill="#10103F"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M16.6421 29.4061H24.0771V26.5821H16.6421V29.4061Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M13.4622 33.3245C13.4622 36.0745 11.2322 38.3045 8.48124 38.3045C5.73024 38.3045 3.50024 36.0745 3.50024 33.3245C3.50024 30.5735 5.73024 28.3435 8.48124 28.3435C11.2322 28.3435 13.4622 30.5735 13.4622 33.3245Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M13.4622 33.3245C13.4622 36.0745 11.2322 38.3045 8.48124 38.3045C5.73024 38.3045 3.50024 36.0745 3.50024 33.3245C3.50024 30.5735 5.73024 28.3435 8.48124 28.3435C11.2322 28.3435 13.4622 30.5735 13.4622 33.3245Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M10.5337 33.3245C10.5337 34.4575 9.61469 35.3765 8.48069 35.3765C7.34669 35.3765 6.42969 34.4575 6.42969 33.3245C6.42969 32.1905 7.34669 31.2715 8.48069 31.2715C9.61469 31.2715 10.5337 32.1905 10.5337 33.3245Z"
                                                                      fill="#10103F"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M36.9027 33.3245C36.9027 36.0745 34.6737 38.3045 31.9227 38.3045C29.1717 38.3045 26.9407 36.0745 26.9407 33.3245C26.9407 30.5735 29.1717 28.3435 31.9227 28.3435C34.6737 28.3435 36.9027 30.5735 36.9027 33.3245Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M36.9027 33.3245C36.9027 36.0745 34.6737 38.3045 31.9227 38.3045C29.1717 38.3045 26.9407 36.0745 26.9407 33.3245C26.9407 30.5735 29.1717 28.3435 31.9227 28.3435C34.6737 28.3435 36.9027 30.5735 36.9027 33.3245Z"
                                                                      stroke="#10103F" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M33.9741 33.3245C33.9741 34.4575 33.0551 35.3765 31.9221 35.3765C30.7881 35.3765 29.8701 34.4575 29.8701 33.3245C29.8701 32.1905 30.7881 31.2715 31.9221 31.2715C33.0551 31.2715 33.9741 32.1905 33.9741 33.3245Z"
                                                                      fill="#10103F"></path>
                                                                <path d="M26 34.5H10" stroke="#10103F"
                                                                      stroke-linejoin="round">
                                                                </path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Siding</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--hot-tub" data-categories="exterior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Hot Tubs</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--door" data-categories="exterior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Doors</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--flooring" data-categories="interior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>

                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Flooring</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--walk-in-tub"
                                                data-categories="interior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Walk In Tubs</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--cabinet" data-categories="interior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Cabinets</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--kitchen" data-categories="interior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.373 38.416H29.83V31.051H18.373V38.416Z"
                                                                      fill="#070441">
                                                                </path>
                                                                <path d="M18.3726 28.1866H29.8296"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Kitchen Remodel</div>
                                                </a>
                                            </li>
                                            <li className="links__item links__item--stair-lift"
                                                data-categories="interior"
                                                data-index="0">
                                                <a className="links__item-link">
                                                    <div className="links__item-icon">
                                                        <i>
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M32.2847 24.504H42.7017V41.689H32.2847"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                                      fill="#D5DBF1"></path>
                                                                <path d="M15.9175 24.504H5.49951V41.689H15.9175"
                                                                      stroke="#070441"
                                                                      stroke-linejoin="round"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      fill="white"></path>
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                                      stroke="#070441" stroke-linejoin="round"></path>
                                                                <path d="M13.4626 28.1866H7.95459"
                                                                      stroke="#070441"></path>
                                                                <path d="M34.7397 28.1866H40.2467"
                                                                      stroke="#070441"></path>
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <div className="links__item-name">Stair Lifts</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container" style={{margin: "-24px"}}>
                    <div className="ar-block__cta">
                        <a id="ar-block-cta"> </a>
                    </div>
                </div>
            </div>
            <div className="page-content deferred">
                <div className="byp-block" style={{backgroundColor: "#f1f1f1"}}>
                    <div className="byp-block__container">
                        <h2 className="byp-block__title" style={{margin: "30px 0"}}>
                            Begin Your Project With ReModelCompare
                        </h2>
                        <div className="byp-block__row">
                            <a className="byp-block__step byp-block__step--a">
                                <img className="byp-block__step-image lazyloaded" src="template/images/Research.svg"
                                     alt=""
                                     width="100"
                                     height="100" data-ll-status="loaded"/>
                                <div className="byp-block__step-info">
                                    <h3 className="byp-block__step-title">
                                        Research and Plan your Project
                                    </h3>
                                    <p className="byp-block__step-text">
                                        Explore our resource library so you can begin your home
                                        improvement project with confidence.
                                    </p>
                                </div>
                            </a>

                            <div className="byp-block__step byp-block__step--div" tabIndex="0" role="button"
                                 aria-controls="byp-1">
                                <img className="byp-block__step-image lazyloaded" src="template/images/Connect.svg"
                                     alt=""
                                     width="100"
                                     height="100" data-ll-status="loaded"/>
                                <div className="byp-block__step-info">
                                    <h3 className="byp-block__step-title">
                                        Connect with Professionals
                                    </h3>
                                    <p className="byp-block__step-text">
                                        Match with local contractors and remodelers. Find the best
                                        professional and price for your project.
                                    </p>
                                </div>
                            </div>
                            <div className="byp-block__expandable" role="region" id="byp-1">
                                <div className="hiw-block" id="hiw-block">
                                    <h3 className="hiw-block__title">How It Works</h3>
                                    <ul className="swiper-wrapper hiw-block__list">
                                        <li className="swiper-slide hiw-block__list-item">
                                            <img
                                                src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%20100%20100&#39;%3E%3C/svg%3E"
                                                className="rocket-lazyload hiw-block__list-image"
                                                alt="Tell us about your home improvement project." width="100"
                                                height="100"
                                                data-lazy-src="https://modernize.com/wp-content/uploads/2021/07/About-Your-Project.svg"/>

                                            <h4 className="hiw-block__list-title">
                                                Tell us about your home improvement project.
                                            </h4>

                                            <div className="hiw-block__list-copy">
                                                Answer a few questions about your needs and preferences
                                                for your upcoming project.
                                            </div>
                                        </li>
                                        <li className="swiper-slide hiw-block__list-item">
                                            <img
                                                src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%20100%20100&#39;%3E%3C/svg%3E"
                                                className="rocket-lazyload hiw-block__list-image"
                                                alt="Modernize pairs you with professionals." width="100" height="100"
                                                data-lazy-src="https://modernize.com/wp-content/uploads/2021/07/Contact.svg"/>

                                            <h4 className="hiw-block__list-title">
                                                Modernize pairs you with professionals.
                                            </h4>

                                            <div className="hiw-block__list-copy">
                                                We will match you with up to four reliable local
                                                professionals by email, phone, and text.
                                            </div>
                                        </li>
                                        <li className="swiper-slide hiw-block__list-item">
                                            <img
                                                src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%20100%20100&#39;%3E%3C/svg%3E"
                                                className="rocket-lazyload hiw-block__list-image"
                                                alt="Select your contractor or remodeler." width="100" height="100"
                                                data-lazy-src="https://modernize.com/wp-content/uploads/2021/07/Select-a-Contractor.svg"/>

                                            <h4 className="hiw-block__list-title">
                                                Select your contractor or&nbsp;remodeler.
                                            </h4>

                                            <div className="hiw-block__list-copy">
                                                Read recent contractor reviews and schedule no-obligation
                                                appointments.
                                            </div>
                                        </li>
                                        <li className="swiper-slide hiw-block__list-item">
                                            <img
                                                src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%20100%20100&#39;%3E%3C/svg%3E"
                                                className="rocket-lazyload hiw-block__list-image"
                                                alt="Begin your project with confidence." width="100" height="100"
                                                data-lazy-src="https://modernize.com/wp-content/uploads/2021/07/Start-Your-Project.svg"/>

                                            <h4 className="hiw-block__list-title">
                                                Begin your project with confidence.
                                            </h4>

                                            <div className="hiw-block__list-copy">
                                                Read contractor reviews, compare quotes, and book
                                                appointments with professionals.
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="hiw-block__pagination"></div>
                                </div>
                            </div>

                            <div className="byp-block__step byp-block__step--div" tabIndex="0" role="button"
                                 aria-controls="byp-2">
                                <img className="byp-block__step-image lazyloaded" src="template/images/Calculate.svg"
                                     alt=""
                                     width="100"
                                     height="100" data-ll-status="loaded"/>
                                <div className="byp-block__step-info">
                                    <h3 className="byp-block__step-title">Calculate Project Costs</h3>
                                    <p className="byp-block__step-text">
                                        Plan your budget based on data from thousands of residential
                                        contractor projects completed across the United States.
                                    </p>
                                </div>
                            </div>
                            <div className="byp-block__expandable" role="region" id="byp-2">
                                <div className="cost-card__block" id="cost-cards-block">
                                    <h3 className="hiw-block__title">Select a Cost Calculator</h3>

                                    <div className="swiper-wrapper cost-card__row">
                                        <a className="swiper-slide cost-card cost-card--windows">
                                            <img className="cost-card__icon" width="40" height="40"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%2040%2040&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/windows.svg"/>
                                            <noscript><img
                                                className="cost-card__icon" width="40" height="40"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/windows.svg"
                                                alt=""/></noscript>
                                            <h4 className="cost-card__title">Windows</h4>
                                            <img className="cost-card__background"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/windows.png"/>
                                            <noscript><img
                                                className="cost-card__background"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/windows.png"
                                                alt=""/></noscript>
                                        </a>

                                        <a className="swiper-slide cost-card cost-card--hvac">
                                            <img className="cost-card__icon" width="40" height="40"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%2040%2040&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/hvac.svg"/>
                                            <noscript><img
                                                className="cost-card__icon" width="40" height="40"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/hvac.svg"
                                                alt=""/></noscript>
                                            <h4 className="cost-card__title">Heating and A/C</h4>
                                            <img className="cost-card__background"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/hvac.png"/>
                                            <noscript><img
                                                className="cost-card__background"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/hvac.png"
                                                alt=""/></noscript>
                                        </a>

                                        <a className="swiper-slide cost-card cost-card--roofing">
                                            <img className="cost-card__icon" width="40" height="40"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%2040%2040&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/roofing.svg"/>
                                            <noscript><img
                                                className="cost-card__icon" width="40" height="40"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/roofing.svg"
                                                alt=""/></noscript>
                                            <h4 className="cost-card__title">Roofing</h4>
                                            <img className="cost-card__background"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/roofing.png"/>
                                            <noscript><img
                                                className="cost-card__background"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/roofing.png"
                                                alt=""/></noscript>
                                        </a>

                                        <a className="swiper-slide cost-card cost-card--siding">
                                            <img className="cost-card__icon" width="40" height="40"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%2040%2040&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/siding.svg"/>
                                            <noscript><img
                                                className="cost-card__icon" width="40" height="40"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/siding.svg"
                                                alt=""/></noscript>
                                            <h4 className="cost-card__title">Siding</h4>
                                            <img className="cost-card__background"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/siding.png"/>
                                            <noscript><img
                                                className="cost-card__background"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/siding.png"
                                                alt=""/></noscript>
                                        </a>

                                        <a className="swiper-slide cost-card cost-card--bathroom">
                                            <img className="cost-card__icon" width="40" height="40"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%2040%2040&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/bathroom.svg"/>
                                            <noscript><img
                                                className="cost-card__icon" width="40" height="40"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/icon/bathroom.svg"
                                                alt=""/></noscript>
                                            <h4 className="cost-card__title">Bathroom</h4>
                                            <img className="cost-card__background"
                                                 src="data:image/svg+xml,%3Csvg%20xmlns=&#39;http://www.w3.org/2000/svg&#39;%20viewBox=&#39;0%200%200%200&#39;%3E%3C/svg%3E"
                                                 alt=""
                                                 data-lazy-src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/bathroom.png"/>
                                            <noscript><img
                                                className="cost-card__background"
                                                src="https://modernize.com/wp-content/themes/modernize/dist/images/cost-cards/background/bathroom.png"
                                                alt=""/></noscript>
                                        </a>
                                    </div>

                                    <div className="cost-card__copy">
                                        <h5 className="cost-card__copy-title">
                                            Don't see your project type?
                                        </h5>
                                        <p className="cost-card__copy-text">
                                            Modernize is always working to provide updated pricing
                                            information for homeowners.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="ha--projects_rebrand_container" style={{"backgroundColor": "#f1f1f1"}}>
                    <div className="l-content-width">
                        <section id="most-popular-projects">
                            <div className="row">
                                <div className="col twelvecol">
                                    <div className="section-title-container">
                                        <h2 className="t-header-secondary">Most Popular Projects</h2>
                                    </div>
                                    <ul className="category-list">
                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10216">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M16.9825 39.5068H8.50049V32.2358H16.9825C18.9865 32.2358 20.6185 30.6048 20.6185 28.6008V24.9648H27.8885V28.6008C27.8885 34.6138 22.9965 39.5068 16.9825 39.5068Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M16.9825 39.5068H8.50049V32.2358H16.9825C18.9865 32.2358 20.6185 30.6048 20.6185 28.6008V24.9648H27.8885V28.6008C27.8885 34.6138 22.9965 39.5068 16.9825 39.5068Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M40.0068 20.1181V8.00009" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M8.5 29.8124V41.9294" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M20.6321 26.701H27.7711V23.235H20.6321V26.701Z"
                                                          fill="#10103F"></path>
												</svg>
											</i>
										</span>
                                            </a>

                                            <a className="category-title"
                                               data-oid="-10216">
                                                Plumbing
                                            </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-13001">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M32.2839 41.689H42.7009V24.504H32.2839V41.689Z"
                                                          fill="#D5DBF1"></path>
													<path d="M32.2847 24.504H42.7017V41.689H32.2847" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M5.49902 41.689H15.917V24.504H5.49902V41.689Z"
                                                          fill="#D5DBF1"></path>
													<path d="M15.9175 24.504H5.49951V41.689H15.9175" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.9175 16.3204L21.5045 10.7264V6.5004H26.6965V10.7264L32.2845 16.3044L21.5045 16.3204H15.9175Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path d="M13.4626 28.1866H7.95459" stroke="#070441"></path>
													<path d="M34.7397 28.1866H40.2467" stroke="#070441"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.918 24.504V41.689H32.284V31.622V24.504H15.918Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.9175 24.504H32.2845V31.622V41.689H15.9175V24.504Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M18.373 38.416H29.83V31.051H18.373V38.416Z" fill="#070441">
													</path>
													<path d="M18.3726 28.1866H29.8296" stroke="#070441"></path>
												</svg>
											</i>
										</span>
                                            </a>
                                            <a className="category-title"
                                               data-oid="-13001">
                                                Kitchen Remodel
                                            </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-13002">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.4205 27.8078H40.2455V35.5298C40.2455 39.3208 37.1475 42.3938 33.3245 42.3938H15.4205C11.5985 42.3938 8.49951 39.3208 8.49951 35.5298V27.8078H11.2735H15.4205Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.4205 27.8078H40.2455V35.5298C40.2455 39.3208 37.1475 42.3938 33.3245 42.3938H15.4205C11.5985 42.3938 8.49951 39.3208 8.49951 35.5298V27.8078H11.2735H15.4205Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M8.49951 36.3879H40.2455C40.2455 39.7039 37.5565 42.3939 34.2395 42.3939H14.5055C11.1885 42.3939 8.49951 39.7039 8.49951 36.3879Z"
                                                          fill="#070441"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M26.9976 15.7961H29.9496C29.9496 12.9521 27.6446 10.6481 24.8016 10.6481C23.1536 10.6481 21.6866 11.4221 20.7446 12.6271C20.0606 13.5011 19.6536 14.6011 19.6536 15.7961H22.7296H26.9976Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M26.9976 15.7961H29.9496C29.9496 12.9521 27.6446 10.6481 24.8016 10.6481C23.1536 10.6481 21.6866 11.4221 20.7446 12.6271C20.0606 13.5011 19.6536 14.6011 19.6536 15.7961H22.7296H26.9976Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path
                                                        d="M35.0973 27.6604V10.6174C35.0973 7.7914 32.7923 5.5004 29.9503 5.5004C27.1073 5.5004 24.8013 7.7914 24.8013 10.6174"
                                                        stroke="#070441" stroke-linejoin="round"></path>
													<path d="M21.7983 19.6565V23.0895" stroke="#070441"
                                                          stroke-linecap="round"></path>
													<path d="M24.6938 19.6565V23.0895" stroke="#070441"
                                                          stroke-linecap="round"></path>
													<path d="M27.5474 19.6565V23.0895" stroke="#070441"
                                                          stroke-linecap="round"></path>
												</svg>
											</i>
										</span>
                                            </a>

                                            <a className="category-title"
                                               data-oid="-13002">
                                                Bathroom Remodel
                                            </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10220">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M27.5024 12.5006H42.5044V28.2526H27.5024" fill="white">
													</path>
													<path d="M27.5024 12.5006H42.5044V28.2526H27.5024" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M6.49902 39.5035H23.751V9.5005H6.49902V39.5035Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M6.5 39.5035H23.752V9.4995H6.5V39.5035Z" stroke="#070441">
													</path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M20.0017 24.1266C20.8297 24.1266 21.5017 24.7986 21.5017 25.6266C21.5017 26.4556 20.8297 27.1266 20.0017 27.1266C19.1737 27.1266 18.5017 26.4556 18.5017 25.6266C18.5017 24.7986 19.1737 24.1266 20.0017 24.1266Z"
                                                          fill="#070441"></path>
													<path d="M34.6284 12.5006V28.2526" stroke="#070441"
                                                          stroke-linecap="square"></path>
													<path d="M27.8774 20.3766H42.1294" stroke="#070441"
                                                          stroke-linecap="square"></path>
												</svg>
											</i>
										</span>
                                            </a>
                                            <a className="category-title"
                                               data-oid="-10220">
                                                Windows &amp;<br/>
                                                Doors
                                            </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10217">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M14.5325 15.5006L25.6835 28.8696H43.8445L32.5775 15.5006H14.5325ZM3.38354 28.8696L14.5325 15.5006L3.38354 28.8696Z"
                                                          fill="#D5DBF1"></path>
													<path
                                                        d="M3.38354 28.8694L14.5325 15.5004L25.6835 28.8694H43.8445L32.5775 15.5004H14.5325"
                                                        stroke="#070441" stroke-linejoin="round"></path>
													<path d="M25.4939 33.4173H44.1209" stroke="#070441"
                                                          stroke-linejoin="round"></path>
												</svg>
											</i>
										</span>
                                            </a>
                                            <a className="category-title"
                                               data-oid="-10217">
                                                Roofing &amp;<br/>
                                                Plumbing
                                            </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10207">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.5 41.6985H34.828V7.5005H13.5V41.6985Z" fill="#D5DBF1">
													</path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.5 41.6975H34.828V7.4995H13.5V41.6975Z" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M18.696 22.0475H29.278V12.7885H18.696V22.0475Z"
                                                          fill="white">
													</path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M18.696 22.0475H29.278V12.7885H18.696V22.0475Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M22.2229 15.1656V16.9296" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M25.7502 15.1656V16.9296" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M23.9963 17.9205V19.6845" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M18.696 36.4545H29.278V27.1955H18.696V36.4545Z"
                                                          fill="white">
													</path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M18.696 36.4545H29.278V27.1955H18.696V36.4545Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M22.2229 29.5719V31.3359" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M25.7502 29.5719V31.3359" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M23.9963 32.3277V34.0917" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
												</svg>
											</i>
										</span>
                                            </a>

                                            <a className="category-title"
                                               data-oid="-10207">
                                                Electrical &amp;<br/>
                                                Computers
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section id="seasonal-projects" style={{"paddingBottom": "55px"}}>
                            <div className="row">
                                <div className="col twelvecol">
                                    <div className="section-title-container">
                                        <h2 className="t-header-secondary">Get a Head Start on Spring</h2>
                                    </div>
                                    <ul className="category-list">
                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-12014">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M21.506 16.8764L17.993 24.1734C17.765 24.6544 17.646 25.1804 17.646 25.7134V41.8474H30.571V25.7134C30.571 25.1804 30.452 24.6544 30.224 24.1734L26.645 16.8234"
                                                          fill="#D5DBF1"></path>
													<path
                                                        d="M21.506 16.8764L17.993 24.1734C17.765 24.6544 17.646 25.1804 17.646 25.7134V41.8474H30.571V25.7134C30.571 25.1804 30.452 24.6544 30.224 24.1734L26.645 16.8234"
                                                        stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M26.8019 10.8618V17.4008H21.3489V12.1568" fill="#15123F">
													</path>
													<path d="M26.8019 10.8618V17.4008H21.3489V12.1568" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M31.258 9.37149C27.548 9.37449 22.891 12.5205 18.936 12.2445C18.146 12.2445 17.5 11.5985 17.5 10.8075V7.93549C17.5 7.14549 18.146 6.50049 18.936 6.50049H31.258V9.37149Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M31.258 9.37149C27.548 9.37449 22.891 12.5205 18.936 12.2445C18.146 12.2445 17.5 11.5985 17.5 10.8075V7.93549C17.5 7.14549 18.146 6.50049 18.936 6.50049H31.258V9.37149Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path
                                                        d="M26.7549 10.2719C26.7549 10.2719 27.7479 13.5549 30.8299 14.9549"
                                                        stroke="#10103F" stroke-linejoin="round"></path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-12014">
                                            Cleaning &amp;<br/>
                                            Maid Service
                                        </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10591">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M11.8879 36.8931H28.4299V29.7551H11.8879V36.8931Z"
                                                          fill="#D5DBF1"></path>
													<path d="M11.8879 29.7552H28.4299" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M26.0512 26.3802H14.6692C14.1162 26.3802 13.6692 25.9332 13.6692 25.3802V21.2432C13.6692 20.1392 14.5652 19.2432 15.6692 19.2432H25.0512C26.1552 19.2432 27.0512 20.1392 27.0512 21.2432V25.3802C27.0512 25.9332 26.6042 26.3802 26.0512 26.3802Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M26.0512 26.3802H14.6692C14.1162 26.3802 13.6692 25.9332 13.6692 25.3802V21.2432C13.6692 20.1392 14.5652 19.2432 15.6692 19.2432H25.0512C26.1552 19.2432 27.0512 20.1392 27.0512 21.2432V25.3802C27.0512 25.9332 26.6042 26.3802 26.0512 26.3802Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M25.8391 29.7552L45.2401 10.3542" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M16.6421 29.4061H24.0771V26.5821H16.6421V29.4061Z"
                                                          fill="#10103F"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M16.6421 29.4061H24.0771V26.5821H16.6421V29.4061Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.4622 33.3245C13.4622 36.0745 11.2322 38.3045 8.48124 38.3045C5.73024 38.3045 3.50024 36.0745 3.50024 33.3245C3.50024 30.5735 5.73024 28.3435 8.48124 28.3435C11.2322 28.3435 13.4622 30.5735 13.4622 33.3245Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.4622 33.3245C13.4622 36.0745 11.2322 38.3045 8.48124 38.3045C5.73024 38.3045 3.50024 36.0745 3.50024 33.3245C3.50024 30.5735 5.73024 28.3435 8.48124 28.3435C11.2322 28.3435 13.4622 30.5735 13.4622 33.3245Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M10.5337 33.3245C10.5337 34.4575 9.61469 35.3765 8.48069 35.3765C7.34669 35.3765 6.42969 34.4575 6.42969 33.3245C6.42969 32.1905 7.34669 31.2715 8.48069 31.2715C9.61469 31.2715 10.5337 32.1905 10.5337 33.3245Z"
                                                          fill="#10103F"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M36.9027 33.3245C36.9027 36.0745 34.6737 38.3045 31.9227 38.3045C29.1717 38.3045 26.9407 36.0745 26.9407 33.3245C26.9407 30.5735 29.1717 28.3435 31.9227 28.3435C34.6737 28.3435 36.9027 30.5735 36.9027 33.3245Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M36.9027 33.3245C36.9027 36.0745 34.6737 38.3045 31.9227 38.3045C29.1717 38.3045 26.9407 36.0745 26.9407 33.3245C26.9407 30.5735 29.1717 28.3435 31.9227 28.3435C34.6737 28.3435 36.9027 30.5735 36.9027 33.3245Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M33.9741 33.3245C33.9741 34.4575 33.0551 35.3765 31.9221 35.3765C30.7881 35.3765 29.8701 34.4575 29.8701 33.3245C29.8701 32.1905 30.7881 31.2715 31.9221 31.2715C33.0551 31.2715 33.9741 32.1905 33.9741 33.3245Z"
                                                          fill="#10103F"></path>
													<path d="M26 34.5H10" stroke="#10103F" stroke-linejoin="round">
													</path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-10591">
                                            Lawncare &amp;<br/>
                                            Sprinklers
                                        </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-12074">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M24.6463 34.0494C26.8733 34.0374 29.1012 34.0834 31.3253 33.9954C34.1023 33.8844 36.5983 31.9164 37.3403 29.3854C38.2173 26.3994 37.2563 23.3944 34.7933 21.7274C34.1333 21.2804 34.0043 20.9084 34.2213 20.1504C35.0123 17.3734 33.6293 14.1234 31.0953 12.7224C30.5963 12.4464 30.4443 12.1504 30.4473 11.5984C30.4592 9.30942 29.6573 7.35242 27.7963 5.95842C25.5973 4.31242 23.1773 4.03742 20.7023 5.24642C18.1123 6.51042 16.9143 8.70842 16.8983 11.5494C16.8953 12.1444 16.7443 12.4554 16.2033 12.7584C13.5103 14.2664 12.2103 17.3494 13.0023 20.3204C13.1743 20.9644 13.0983 21.3014 12.4963 21.6904C10.1013 23.2364 8.99125 26.2524 9.72225 28.9794C10.4793 31.8014 12.9413 33.8644 15.8333 33.9964C17.6603 34.0804 19.4923 34.0394 21.3223 34.0534L24.6463 34.0494Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M24.6463 34.0494C26.8733 34.0374 29.1013 34.0834 31.3253 33.9954C34.1023 33.8844 36.5983 31.9164 37.3403 29.3854C38.2173 26.3994 37.2563 23.3944 34.7933 21.7274C34.1333 21.2804 34.0043 20.9084 34.2213 20.1504C35.0123 17.3734 33.6293 14.1234 31.0953 12.7224C30.5963 12.4464 30.4443 12.1504 30.4473 11.5984C30.4593 9.30942 29.6573 7.35242 27.7963 5.95842C25.5973 4.31242 23.1773 4.03742 20.7023 5.24642C18.1123 6.51042 16.9143 8.70842 16.8983 11.5494C16.8953 12.1444 16.7443 12.4554 16.2033 12.7584C13.5103 14.2664 12.2103 17.3494 13.0023 20.3204C13.1743 20.9644 13.0983 21.3014 12.4963 21.6904C10.1013 23.2364 8.99125 26.2524 9.72225 28.9794C10.4793 31.8014 12.9413 33.8644 15.8333 33.9964C17.6603 34.0804 19.4923 34.0394 21.3223 34.0534L24.6463 34.0494Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M16.8435 24.022L23.4265 29.41" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M18.7615 18.773L23.4265 22.243" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M30.3484 24.022L23.7654 29.41" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M28.4294 18.773L23.7654 22.243" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path d="M23.5847 16.1304V41.4534" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M20.9241 43.7919H26.2471V41.7919H20.9241V43.7919Z"
                                                          fill="#10103F"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M20.9241 43.7919H26.2471V41.7919H20.9241V43.7919Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-12074">
                                            Tree Service
                                        </a>
                                        </li>

                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-12001">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M6.79932 20.453L10.5723 20.502V41.077H24.1323V8.784L6.79932 20.453Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M24.2727 41.0765H37.8327V20.5015L41.6067 20.4525L24.2727 8.78448V41.0765Z"
                                                          fill="white"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M6.50049 20.5015L24.1465 8.49951L41.7915 20.5015H6.50049Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M10.572 41.0769H37.719V20.5019H10.572V41.0769Z"
                                                          stroke="#070441" stroke-linejoin="round"></path>
													<path d="M37.719 34.2183H24.146" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path d="M37.719 27.3599H24.146" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path d="M24.146 41.0767V8.49969" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path d="M30.933 13.6441H24.146" stroke="#070441"
                                                          stroke-linejoin="round"></path>
													<path d="M30.9331 13.6441V41.0771" stroke="#070441"
                                                          stroke-linejoin="round"></path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-12001">
                                            Additions &amp;<br/>
                                            Remodels
                                        </a>
                                        </li>
                                        <li className="round-item">
                                            <a className="round-category-item"
                                               data-oid="-10345">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M5 8.49969V16.2177H31.465L27.01 8.49969H5Z" fill="#D5DBF1">
													</path>
													<path d="M4.99951 16.2175H31.4645L27.0095 8.49951H4.99951"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M5 18.7894V26.5064H37.404L32.949 18.7894H5Z"
                                                          fill="#D5DBF1">
													</path>
													<path d="M4.99951 26.5065H37.4045L32.9495 18.7895H4.99951"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M5 28.7704V39.8824H43.167V36.4874L38.712 28.7704H5Z"
                                                          fill="#D5DBF1"></path>
													<path d="M4.99951 28.7702H38.7125L43.1675 36.4882V39.8822H4.99951"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path d="M43.1676 36.488H5.00464" stroke="#10103F"
                                                          stroke-linejoin="round"></path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-10345">
                                            Decks &amp;<br/>
                                            Porches
                                        </a>
                                        </li>
                                        <li className="round-item">
                                            <a className="round-category-item" data-oid="-12030">
										<span className="icon-container icon-grid-banner-test">
											<i><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.9632 31.5078V18.4538V14.7218V11.4428C15.9632 11.1968 15.8902 10.9558 15.7542 10.7518L13.2662 7.01981C12.8042 6.32681 11.6572 6.32681 11.1952 7.01981L8.70924 10.7498C8.57224 10.9548 8.50024 11.1948 8.50024 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C8.50024 40.9038 9.05724 41.4598 9.74424 41.4598H14.7192C15.4062 41.4598 15.9632 40.9038 15.9632 40.2158V35.2388V31.5078Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M15.9632 31.5078V18.4538V14.7218V11.4428C15.9632 11.1968 15.8902 10.9558 15.7542 10.7518L13.2662 7.01981C12.8042 6.32681 11.6572 6.32681 11.1952 7.01981L8.70924 10.7498C8.57224 10.9548 8.50024 11.1948 8.50024 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C8.50024 40.9038 9.05724 41.4598 9.74424 41.4598H14.7192C15.4062 41.4598 15.9632 40.9038 15.9632 40.2158V35.2388V31.5078Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M28.3812 31.5078V18.4538V14.7218V11.4428C28.3812 11.1968 28.3082 10.9558 28.1722 10.7518L25.6842 7.01981C25.2222 6.32681 24.0752 6.32681 23.6132 7.01981L21.1272 10.7498C20.9902 10.9548 20.9182 11.1948 20.9182 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C20.9182 40.9038 21.4752 41.4598 22.1612 41.4598H27.1372C27.8242 41.4598 28.3812 40.9038 28.3812 40.2158V35.2388V31.5078Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M28.3812 31.5078V18.4538V14.7218V11.4428C28.3812 11.1968 28.3082 10.9558 28.1722 10.7518L25.6842 7.01981C25.2222 6.32681 24.0752 6.32681 23.6132 7.01981L21.1272 10.7498C20.9902 10.9548 20.9182 11.1948 20.9182 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C20.9182 40.9038 21.4752 41.4598 22.1612 41.4598H27.1372C27.8242 41.4598 28.3812 40.9038 28.3812 40.2158V35.2388V31.5078Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M40.7992 31.5078V18.4538V14.7218V11.4428C40.7992 11.1968 40.7262 10.9558 40.5902 10.7518L38.1022 7.01981C37.6402 6.32681 36.4932 6.32681 36.0312 7.01981L33.5442 10.7498C33.4082 10.9548 33.3352 11.1948 33.3352 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C33.3352 40.9038 33.8932 41.4598 34.5792 41.4598H39.5552C40.2412 41.4598 40.7992 40.9038 40.7992 40.2158V35.2388V31.5078Z"
                                                          fill="#D5DBF1"></path>
													<path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M40.7992 31.5078V18.4538V14.7218V11.4428C40.7992 11.1968 40.7262 10.9558 40.5902 10.7518L38.1022 7.01981C37.6402 6.32681 36.4932 6.32681 36.0312 7.01981L33.5442 10.7498C33.4082 10.9548 33.3352 11.1948 33.3352 11.4408V15.9658V17.2098V32.7518V33.9948V40.2158C33.3352 40.9038 33.8932 41.4598 34.5792 41.4598H39.5552C40.2412 41.4598 40.7992 40.9038 40.7992 40.2158V35.2388V31.5078Z"
                                                          stroke="#10103F" stroke-linejoin="round"></path>
												</svg>
											</i>
										</span> </a><a className="category-title"
                                                       data-oid="-12030">
                                            Fences
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="ha--fixedPrice_rebrand_container content-wrapper" id="menu2">
                    <div className="l-content-width">
                        <div className="row">
                            <div className="col sixcol fixed-price-image-wrapper">
                                <div className="image-primary-backdrop"></div>
                            </div>
                            <div className="col sixcol">
                                <div className="max-width-responsive-wrapper fixed-price-content">
                                    <form onSubmit={handleSubmit} action="#" method="post" className="contact-form"
                                          name='contact-form' id='contact-form'>
                                        <input ref={lead_idRef} id="leadid_token" name="universal_leadid" type="hidden"
                                               value=""/>
                                        <div id="main">
                                            <h4>Plan Your Project</h4>
                                            <div className="forminput display-none">
                                                <input type="text" name="name" id="name" placeholder="Full Name"
                                                       value={name} onChange={e => setName(e.target.value)}/>
                                                <span className="bar"></span>
                                            </div>
                                            <div className="forminput display-none">
                                                <select name="request_type" value={requesttype}
                                                        onChange={e => setRequestType(e.target.value)}
                                                        style={{
                                                            "color": "#bbb",
                                                            "width": "100%",
                                                            "background": "white",
                                                            "border": "none",
                                                            "borderBottom": "1px solid #070441",
                                                            "borderRadius": "0",
                                                            "height": "40px"
                                                        }}>
                                                    <option value="Plumbing">Plumbing</option>
                                                    <option value="Kitchen Remodel">Kitchen Remodel</option>
                                                    <option value="Bathroom Remodel">Bathroom Remodel</option>
                                                    <option value="Windows & Doors">Windows & Doors</option>
                                                    <option value="Roofing & Plumbing">Roofing & Plumbing</option>
                                                    <option value="Electrical & Computers">Electrical & Computers
                                                    </option>
                                                    <option value="Cleaning & Maid Service">Cleaning & Maid
                                                        Service
                                                    </option>
                                                    <option value="Lawncare & Sprinklers">Lawncare & Sprinklers
                                                    </option>
                                                    <option value="Tree Service">Tree Service</option>
                                                    <option value="Additions & Remodels">Additions & Remodels
                                                    </option>
                                                    <option value="Decks & Porches">Decks & Porches</option>
                                                    <option value="Fences">Fences</option>
                                                </select>
                                                <span className="bar"></span>
                                            </div>
                                            <div className="forminput display-none">
                                                <input type="text" name="state" id="state" placeholder="State"
                                                       value={state}
                                                       onChange={e => setState(e.target.value)}/>
                                                <span className="bar"></span>
                                            </div>
                                            <div className="forminput display-none">
                                                <input type="text" name="email" id="email" value={email}
                                                       onChange={e => setEmail(e.target.value)}
                                                       placeholder="Email Address"/>
                                                <span className="bar"></span>
                                            </div>
                                            <div className="forminput">
                                                <input type="text" name="phone" id="phone" required="" value={phone}
                                                       onChange={e => setPhone(e.target.value)}
                                                       className="capture_number" placeholder="Phone Number"/>
                                                <span className="bar"></span>
                                            </div>
                                            <div className="forminput"
                                                 style={{marginBottom: "0px", padding: "0", clear: "both"}}>
                                                <p className="Chklbl"
                                                   style={{padding: "0", fontSize: "11px", fontWeight: "normal"}}><input
                                                    type="hidden" id="leadid_tcpa_disclosure"
                                                    value='By clicking the "Get Free Quote" button, I agree to this website <a href="privacy.php">privacy policy</a>, and provide my signature expressly authorising up to <a href="companies.php">three insurance companies or their agents or partner companies</a> to contact me at the email address, landline or wireless phone numbers that I provided even if it is listed on a government Do-Not-Call registry, with insurance quotes or to market their products or services. I agree to receive communications via live, automated telephone dialing system, prerecorded messages, or text messages and acknowledge that consent is not a condition to purchase goods or services.'/>
                                                    [By
                                                    clicking the "Get Free Quote" button, I agree to this
                                                    website's <a href="/privacy">privacy policy</a>, and provide
                                                    my signature expressly authorising up to <a
                                                        href="/partners">three insurance companies or their
                                                        agents or partner companies</a> to contact me at the email
                                                    address, landline or wireless phone numbers that I provided even
                                                    if it is listed on a government Do-Not-Call registry, with
                                                    insurance quotes or to market their products or services. I
                                                    agree to receive communications via live, automated telephone
                                                    dialing system, prerecorded messages, or text messages and
                                                    acknowledge that consent is not a condition to purchase goods or
                                                    services.]</p>
                                            </div>
                                            <div className="button1 text-right">
                                                <input type="button" name="submit" id="submit" onClick={handleSubmit}
                                                       value="Get Free Quote" className="buttoncontacts"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12" id="response"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="reviews-block">
                    <div className="container">
                        <h2 className="reviews-block__title">
                            Here's how we've&nbsp;helped <br/>
                            <b>2M+ homeowners</b> get projects done:
                        </h2>

                        <div className="swiper-container swiper-container-initialized swiper-container-horizontal"
                             id="ho-reviews-swiper-container">
                            <ul className="swiper-wrapper" style={{transform: "translate3d(0px, 0px, 0px)"}}>
                                <li className="swiper-slide card swiper-slide-active"
                                    style={{width: "360px", marginRight: "30px"}}>
                                    <div className="card__avatar">
                                        <img width="73" height="73" src="template/images/female1.svg" alt=""
                                             className="lazyloaded"
                                             data-ll-status="loaded"/>
                                    </div>
                                    <div className="card__review">
                                        "The pros did an excellent job for a reasonable price. Roofing
                                        crew was amazing. I would highly recommend this company."
                                    </div>
                                    <div className="card__author">
                                        <b>Stephanie F.</b>&nbsp;|&nbsp;Charlotte, NC
                                        <div className="card__trade">Roof Replacement</div>
                                    </div>
                                </li>
                                <li className="swiper-slide card swiper-slide-next"
                                    style={{width: "360px", marginRight: "30px"}}>
                                    <div className="card__avatar">
                                        <img width="73" height="73" src="template/images/male3.svg" alt=""
                                             className="lazyloaded"
                                             data-ll-status="loaded"/>
                                    </div>
                                    <div className="card__review">
                                        "They did beautiful work replacing 3 windows for us and we got
                                        them done at a great price."
                                    </div>
                                    <div className="card__author">
                                        <b>Mark Y.</b>&nbsp;|&nbsp;Austin, TX
                                        <div className="card__trade">Windows Replacement</div>
                                    </div>
                                </li>
                                <li className="swiper-slide card" style={{width: "360px", marginRight: "30px"}}>
                                    <div className="card__avatar">
                                        <img width="73" height="73" src="template/images/female2.svg" alt=""
                                             className="lazyloaded"
                                             data-ll-status="loaded"/>
                                    </div>
                                    <div className="card__review">
                                        "The installers were excellent! They did everything they
                                        promised. I got a great price and the new AC Unit works
                                        perfectly!"
                                    </div>
                                    <div className="card__author">
                                        <b>Tina P.</b>&nbsp;|&nbsp;Dallas, TX
                                        <div className="card__trade">AC Unit Replacement</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="swiper-controls">
                                <div
                                    className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
							<span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex="0"
                                  role="button" aria-label="Go to slide 1"></span><span
                                    className="swiper-pagination-bullet"
                                    tabIndex="0" role="button" aria-label="Go to slide 2"></span><span
                                    className="swiper-pagination-bullet" tabIndex="0" role="button"
                                    aria-label="Go to slide 3"></span>
                                </div>
                            </div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
