import React from 'react';
import Footer from "./Footer";

export default function PrivacyPolicy() {
    var BreadCrumbTitle = "Privacy Policy";
    document.title = BreadCrumbTitle;
    return (<>
            <div className="header" id="homeowner-header">
                <div className="container">
                    <div className="header__desktop hidden-xs hidden-sm"><a href="/">
                        <img src="/template/images/logo.png" className="header__logo logo"/>
                    </a>
                    </div>
                    <div className="header__mobile">
                        <a className="header__mobile-logo logo logo--dark-color"
                           aria-label="Modernize"></a>
                        <div className="header__mobile-btns closed" id="toggle-mobile-nav"></div>
                    </div>
                </div>
            </div>
            <div className="home-hero" show-sticky-header="">
                <div className="home-hero__container">
                    <div className="home-hero__bg" id="hero-bg">
                        <picture className="home-hero__bg-picture">
                            <source media="(max-width: 767px)"
                                    srcSet="https://modernize.com/wp-content/uploads/2021/07/modernize-newhero-mob.jpg"/>
                            <img src="/template/images/modernize-newhero-desk.jpg" alt="" className="lazyloaded"
                                 data-ll-status="loaded"/>
                            <noscript><img
                                src="https://modernize.com/wp-content/uploads/2021/07/modernize-newhero-desk.jpg"
                                alt=""/></noscript>
                        </picture>
                    </div>
                    <div className="home-hero__content">
                        <div className="container" style={{marginTop: "40px"}}>
                            <div className="col-sm-12 col-lg-8">
                                <h1 className="home-hero__content-title">
                                    Better&nbsp;Pros. Better&nbsp;Homes.
                                </h1>
                                <h2 className="home-hero__content-subtitle">
                                    ReModelCompare is the reliable and trustworthy solution to
                                    thoughtfully connect homeowners with professionals who provide
                                    home improvement projects and services.
                                </h2>
                            </div>
                            <div className="col-sm-12 col-lg-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container" style={{margin: "-24px"}}>
                    <div className="ar-block__cta">
                        <a id="ar-block-cta"> </a>
                    </div>
                </div>
            </div>
            <div className="page-content deferred">
                <div className="byp-block" style={{"backgroundColor": "#f1f1f1"}}>
                    <div className="byp-block__container">
                        <h2 className="byp-block__title" style={{"margin": "30px 0"}}>
                            Privacy Policy
                        </h2>
                        <div className="byp-block__row">
                            <a className="byp-block__step byp-block__step--a" style={{"width": "100%"}}>
                                <div className="byp-block__step-info" style={{"width": "100%", "textAlign": "left"}}>
                                    <h3 className="byp-block__step-title">
                                        Privacy Policy
                                    </h3>
                                    <p className="byp-block__step-text">
                                        This privacy statement describes how remodelcompare.com collects and uses the
                                        personal information you provide on our Web site: remodelcompare.com It also
                                        describes the choices available to you regarding our use of your personal
                                        information and how you can access and update this information. Collection and
                                        Use of Personal Information We collect the following personal information from
                                        you *Contact Information such as name, email address, mailing address, phone
                                        number *As is true of most Web sites, we automatically gather information about
                                        your computer such as your IP address, browser type, referring/exit pages, and
                                        operating system. We use this information to Send you quote confirmation
                                        *Respond to customer service requests *Administer your account *Respond to your
                                        questions and concerns *when we believe in good faith that disclosure is
                                        necessary to protect our rights, protect your safety or the safety of others,
                                        investigate fraud, or respond to a government request *if remodelcompare is
                                        involved in a merger, acquisition, or sale of all or a portion of its assets,
                                        you will be notified via email and/or a prominent notice on our Web site of any
                                        change in ownership or uses of your personal information, as well as any choices
                                        you may have regarding your personal information. to any other third party with
                                        your prior consent to do so.
                                    </p>
                                    <h3 className="byp-block__step-title">
                                        Cookies and Other Tracking Technologies
                                    </h3>
                                    <p className="byp-block__step-text">
                                        We may use cookies, for example, to keep track of your preferences and profile
                                        information. Cookies are also used to collect general usage and volume
                                        statistical information that does not include personal information. We may use
                                        another company to place cookies on your computer to collect non-personally
                                        identifiable information to compile aggregated statistics for us about visitors
                                        to our site. Web beacons Our Web pages may also contain electronic images known
                                        as Web beacons (sometimes called single-pixel gifs) that are set by our
                                        third-party partners. Web beacons are used along with cookies enabling our
                                        partners to compile aggregated statistics to analyze how our site is used. Links
                                        to Other Web Sites Our Site includes links to other Web sites whose privacy
                                        practices may differ from those of remodelcompare.com If you submit personal
                                        information to any of those sites, your information is governed by their privacy
                                        statements. We encourage you to carefully read the privacy statement of any Web
                                        site you visit. Security he security of your personal information is important
                                        to us. When you enter sensitive information (such as credit card number) on our
                                        order forms, we encrypt the transmission of that information using secure socket
                                        layer technology (SSL). We follow generally accepted industry standards to
                                        protect the personal information submitted to us, both during transmission and
                                        once we receive it. No method of transmission over the Internet, or method of
                                        electronic storage, is 100% secure, however. Therefore, we cannot guarantee its
                                        absolute security. If you have any questions about security on our Web site, you
                                        can contact us at support@remodelcompare.com Notification of Privacy Statement
                                        Changes We may update this privacy statement to reflect changes to our
                                        information practices. If we make any material changes we will notify you by
                                        email (sent to the e-mail address specified in your Submitions) or by means of a
                                        notice on this Site prior to the change becoming effective. We encourage you to
                                        periodically review this page for the latest information on our privacy
                                        practices.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
