import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import TermsConditions from "./components/TermsConditions";
import Partners from "./components/Partners";
import PrivacyPolicy from "./components/PrivacyPolicy";

function MediquotesWeb() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/partners" element={<Partners/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/terms-of-use" element={<TermsConditions/>}/>
                <Route path="/" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default MediquotesWeb;
